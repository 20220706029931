"use client";
import { TypeContactSkeleton } from "@/lib/contentful/contentfulTypes";
import clsx from "clsx";
import { Entry } from "contentful";
import { ContactCard } from "./ContactCard";

export function ContactListClient({
  cards,
}: {
  cards: Entry<TypeContactSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>[];
}) {
  return (
    <section
      className={clsx("grid", "lg:grid-cols-3", "md:grid-cols-2", "gap-5")}
    >
      {cards.map((card) => (
        <ContactCard key={card.sys.id} model={card} />
      ))}
    </section>
  );
}
