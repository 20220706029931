import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { altchaKey as generateKey } from "./AltchaHelper";
interface AltchaProps {
  onStateChange?: (ev: CustomEvent) => void;
}

const Altcha = forwardRef<{ altchaValue: string | null }, AltchaProps>(
  ({ onStateChange }, ref) => {
    const widgetRef = useRef<HTMLElement>(null);
    const [altchaValue, setAltchaValue] = useState<string | null>(null);
    const [apiKey, setApiKey] = useState<string | null>(null);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      if (typeof window !== "undefined") {
        // Dynamically import the altcha widget only on the client
        import("altcha").then(() => {
          if (typeof customElements !== "undefined") {
            const handleStateChange = (ev: Event) => {
              const customEvent = ev as CustomEvent;
              if (customEvent.detail?.payload) {
                setAltchaValue(customEvent.detail.payload);
                onStateChange?.(customEvent);
              }
            };

            const currentWidget = widgetRef.current;
            if (currentWidget) {
              currentWidget.addEventListener(
                "statechange",
                handleStateChange as EventListener,
              );
            }

            return () => {
              if (currentWidget) {
                currentWidget.removeEventListener(
                  "statechange",
                  handleStateChange as EventListener,
                );
              }
            };
          }
        });

        setApiKey(generateKey(window.location.origin));
      }
    }, [onStateChange]);

    useImperativeHandle(
      ref,
      () => ({
        altchaValue,
      }),
      [altchaValue],
    );

    useEffect(() => {
      setIsMounted(true);
    }, []);

    const widgetProps = {
      ref: widgetRef,
      style: { "--altcha-max-width": "100%" } as React.CSSProperties,
      challengeurl: apiKey
        ? `https://eu.altcha.org/api/v1/challenge?apiKey=${apiKey}`
        : "",
    };

    if (!isMounted || !apiKey) {
      return null; // Prevent rendering on the server or before mounting
    }

    return <altcha-widget {...widgetProps} />;
  },
);

Altcha.displayName = "Altcha";
export { Altcha };
