"use client";
import { EmailParams } from "@/app/api/send-email/route";
import { Spinner } from "@/components/icons/Spinner";
import { MTextArea } from "@/ui/MTextArea";
import { MTextInput } from "@/ui/MTextInput";
import { Button } from "@mantine/core";
import clsx from "clsx";
import { useLocale } from "next-intl";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ArrowRight } from "../icons/ArrowRight";
import { Altcha } from "../Altcha/Altcha";

export function ContactForm({ toEmail }: { toEmail: string }) {
  const { control, formState, handleSubmit, trigger, getFieldState } = useForm<
    EmailParams["form"]
  >({
    mode: "all",
  });
  const { errors } = formState;

  const locale = useLocale();
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const {
    submitLabel,
    sentLabel,
    requiredMessage,
    emailValidationMessage,
    phoneValidationMessage,
  } = getLocalMessages({ locale });

  const altchaRef = useRef<{ altchaValue: string | null }>(null);

  const onSubmit = (data: EmailParams["form"]) => {
    const isValid = () => {
      return !(
        getFieldState("email").invalid ||
        getFieldState("message").invalid ||
        getFieldState("name").invalid ||
        getFieldState("phoneNumber").invalid
      );
    };

    if (!isValid()) {
      return;
    }

    setLoading(true);

    if (altchaRef?.current) {
      fetch("/api/send-email", {
        method: "POST",
        body: JSON.stringify({
          toEmail,
          form: data,
          siteUrl: window.location.origin,
        }),
      }).finally(() => {
        setLoading(false);
        setIsSent(true);
      });
    }
  };

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
      className={clsx(
        "rounded-xl bg-white p-8 text-black",
        "dark:bg-darkBlue dark:text-darkBlue20",
      )}
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: requiredMessage }}
        render={({ field, fieldState }) => (
          <MTextInput
            label="Namn"
            {...field}
            required
            value={field.value || ""}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          required: requiredMessage,
          pattern: {
            value: /@.+\..+/,
            message: emailValidationMessage,
          },
        }}
        render={({ field }) => (
          <MTextInput
            required
            label="Email"
            type="email"
            {...field}
            value={field.value || ""}
            error={errors.email?.message}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          validate: (value) => {
            const valueStr = value?.trim() || "";
            if (valueStr === "") {
              return true;
            }

            const pattern = /^\s*[\+0].*/;
            return pattern.test(valueStr) || phoneValidationMessage;
          },
        }}
        render={({ field, fieldState }) => {
          const handlePhoneNumberChange = (
            e: React.ChangeEvent<HTMLInputElement>,
          ) => {
            let value = e.target.value;

            // If the number starts with '07', prepend '+46' and remove the leading '0'
            if (value.trim().startsWith("07")) {
              value = "+46" + value.trim().substring(1);
            }

            field.onChange(value);

            // Manually trigger validation after value is updated
            trigger("phoneNumber");
          };

          return (
            <MTextInput
              label="Kontaktnummer"
              {...field}
              value={field.value || ""}
              onChange={handlePhoneNumberChange}
              error={fieldState.error?.message}
            />
          );
        }}
      />

      <Controller
        name="message"
        control={control}
        rules={{ required: requiredMessage }}
        render={({ field }) => (
          <MTextArea
            required
            label="Meddelande"
            autosize
            minRows={5}
            maxRows={5}
            {...field}
            error={errors.message?.message}
          />
        )}
      />
      <div className="gap mt-8 flex justify-center">
        <Altcha ref={altchaRef} />
        {isSent ? (
          <Button
            type="submit"
            loading={loading}
            className="group"
            classNames={(_theme, props) => ({
              label: clsx(
                "text-white dark:text-primaryBlack buttonsSmall",
                "flex items-center",
              ),
              root: clsx(
                props.disabled ? "bg-darkBlue80" : "bg-secondaryGreen",
                "px-4 py-1 text-center",
              ),
            })}
          >
            <span className="text-center">{sentLabel}</span>
            {loading ? (
              <Spinner className="ml-2" />
            ) : (
              <ArrowRight
                size="S"
                className={clsx(
                  "max-w-0",
                  "group-hoverOrFocus:ml-3 group-hoverOrFocus:md:ml-4",
                  "group-hoverOrFocus:visible group-hoverOrFocus:max-w-lg",
                  "invisible transition-[maw-width]",
                )}
              />
            )}
          </Button>
        ) : (
          <Button
            type="submit"
            loading={loading}
            className="group"
            classNames={(_theme, props) => ({
              label: clsx(
                "text-white dark:text-primaryBlack buttonsSmall",
                "flex items-center",
              ),
              root: clsx(
                props.disabled ? "bg-darkBlue80" : "bg-primaryOrange",
                "px-4 py-1 text-center",
              ),
            })}
          >
            <span className="text-center">{submitLabel}</span>
            {loading ? (
              <Spinner className="ml-2" />
            ) : (
              <ArrowRight
                size="S"
                className={clsx(
                  "max-w-0",
                  "group-hoverOrFocus:ml-3 group-hoverOrFocus:md:ml-4",
                  "group-hoverOrFocus:visible group-hoverOrFocus:max-w-lg",
                  "invisible transition-[maw-width]",
                )}
              />
            )}
          </Button>
        )}
      </div>
    </form>
  );
}

// TODO add these messages to contentful
function getLocalMessages({ locale }: { locale: string }): {
  submitLabel: string;
  sentLabel: string;
  requiredMessage: string;
  phoneValidationMessage: string;
  emailValidationMessage: string;
} {
  if (locale === "en") {
    return {
      submitLabel: "Send",
      sentLabel: "Sent",
      requiredMessage: "Please fill out this field",
      phoneValidationMessage: "Enter a phone number starting with +",
      emailValidationMessage: "Please enter a valid email address",
    };
  }

  return {
    submitLabel: "Send",
    sentLabel: "Skickat",
    requiredMessage: "Vänligen fyll i fältet",
    phoneValidationMessage: "Ange ett telefonnummer som börjar med +",
    emailValidationMessage: "Var god ange en giltig e-postadress",
  };
}
