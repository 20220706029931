import { createChallenge, verifySolution } from "altcha-lib";

export function altchaKey(siteUrl: string): string {
  if (siteUrl.startsWith("https://modity-se-v2.test.internal.modity.me")) {
    return "ckey_010f50a36a9e8fcb4761cd4818eb";
  } else if (siteUrl.startsWith("https://draft.modity.se/")) {
    return "ckey_0144ca460c179691d43a922fa768";
  }
  return "ckey_0180fc8aa186f83d352f8cf65559";
}

export async function VerifyAltcha(siteUrl: string) {
  const altchaApiKey = altchaKey(siteUrl);
  const number = 100;

  const challenge = await createChallenge({
    expires: new Date(Date.now() + 360000),
    number,
    hmacKey: altchaApiKey,
  });

  const IsVerified = await verifySolution(
    {
      algorithm: challenge.algorithm,
      challenge: challenge.challenge,
      number: number,
      salt: challenge.salt,
      signature: challenge.signature,
    },
    altchaApiKey,
  );

  return IsVerified;
}
