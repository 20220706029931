"use client";
import { Children, ReactNode, useState } from "react";
import { useSnapCarousel } from "react-snap-carousel";

export function TestimonialCarousel({ children }: { children: ReactNode[] }) {
  const { scrollRef, snapPointIndexes, pages, goTo, activePageIndex } =
    useSnapCarousel();

  return (
    <figure>
      <section
        className="scrollbar-hide flex snap-x snap-mandatory overflow-x-auto scroll-smooth"
        ref={scrollRef}
      >
        {Children.map(children, (child, index) => (
          <div
            className={`flex min-w-full ${
              snapPointIndexes.has(index) ? "snap-start" : ""
            }`}
            key={index}
          >
            {child}
          </div>
        ))}
      </section>
      <nav className="flex justify-center gap-2 py-3">
        {pages.map((_, index) => (
          <button
            className="group flex h-2 hoverOrFocus:bg-primaryOrange"
            key={index}
            onClick={(e) => {
              e.currentTarget.blur();
              goTo(index);
            }}
          >
            <div
              className={`w-7 self-end group-hoverOrFocus:bg-primaryOrange ${
                activePageIndex === index
                  ? "h-2 bg-primaryOrange"
                  : "h-1 bg-darkBlue80 dark:bg-darkBlue60"
              }`}
            />
          </button>
        ))}
      </nav>
    </figure>
  );
}
