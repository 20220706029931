"use client";
import { ArrowRight } from "@/components/icons/ArrowRight";
import { NewsCard } from "@/components/NewsCard";
import { TypeArticleSkeleton } from "@/lib/contentful/contentfulTypes";
import clsx from "clsx";
import { Entry } from "contentful";
import { useSnapCarousel } from "react-snap-carousel";

export function NewsCarousel({
  items,
}: {
  items: Entry<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>[];
}) {
  const { scrollRef, snapPointIndexes, pages, next, prev, activePageIndex } =
    useSnapCarousel({ initialPages: items.map((_, i) => [i]) });

  return (
    <section>
      <section
        className="scrollbar-hide flex snap-x snap-mandatory gap-5 overflow-x-hidden scroll-smooth"
        ref={scrollRef}
      >
        {items.map((item, index) => (
          <NewsCard
            className={clsx(
              snapPointIndexes.has(index)
                ? "min-w-[370px] max-w-[370px] snap-start"
                : "min-w-[370px] max-w-[370px]",
            )}
            model={item}
            key={item.sys.id}
          />
        ))}
      </section>
      <nav className="flex justify-between">
        <button
          className={clsx(
            "text-primaryOrange",
            "disabled:dark:text-darkBlue80",
            "disabled:text-darkBlue40",
          )}
          disabled={activePageIndex === 0}
          onClick={() => prev()}
        >
          <ArrowRight className="rotate-180" />
        </button>
        <button
          className={clsx(
            "text-primaryOrange",
            "disabled:dark:text-darkBlue80",
            "disabled:text-darkBlue40",
          )}
          disabled={activePageIndex === pages.length - 1}
          onClick={() => next()}
        >
          <ArrowRight />
        </button>
      </nav>
    </section>
  );
}
