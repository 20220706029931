import Image from "next/image";
import { MLink } from "@/components/MLink/MLink";
import { TypeArticleSkeleton } from "@/lib/contentful/contentfulTypes";
import { createNewsLink } from "@/lib/createNewsLink";
import clsx from "clsx";
import { Entry } from "contentful";
import { ArrowRight } from "./icons/ArrowRight";

export function NewsCard({
  model,
  className,
}: {
  model: Entry<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
  className?: string;
}) {
  const image = model.fields.thumbnail;
  const url = createNewsLink({
    slug: model.fields.slug,
  });

  return (
    <MLink
      href={url}
      ariaLabel={model.fields.title}
      className={clsx(className, "group")}
    >
      <section
        className={clsx(
          className,
          "group",
          "relative",
          "block",
          "aspect-square",
          "w-full",
        )}
      >
        <Image
          src={`https:${image?.fields.file?.url}`}
          alt={image?.fields.description || ""}
          fill={true}
          sizes="(max-width: 768px) 300vw, (max-width: 1200px) 150vw, 99vw"
          className={clsx("object-cover")}
        />
        <div
          className={clsx(
            "absolute bottom-0 right-0",
            "flex items-center gap-2",
            "bg-primaryOrange p-3 text-white",
          )}
        >
          <span
            className={clsx(
              "buttonsSmall",
              "invisible max-w-0 overflow-hidden whitespace-nowrap transition-[max-width]",
              "group-hoverOrFocus:visible group-hoverOrFocus:max-w-lg",
            )}
          >
            {model.fields.buttonLabel}
          </span>
          <ArrowRight className="inline-block" size="XS" />
        </div>
      </section>
      <p className={clsx("copySmall", "mb-1 mt-5")}>
        {model.fields.category?.fields.title}
      </p>
      <h3
        className={clsx(
          "copyLarge line-clamp-2 w-full overflow-hidden ",
          "group-hoverOrFocus:cur group-hoverOrFocus:underline",
        )}
      >
        {model.fields.title}
      </h3>
    </MLink>
  );
}
