import { TextInput, TextInputProps, __InputStylesNames } from "@mantine/core";
import clsx from "clsx";
import { forwardRef } from "react";

export const MTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function MTextInput(props, ref) {
    const { error, disabled } = props;
    const classNames = getCorrectClassNames({
      hasErrorProp: !!error,
      hasDisabledProp: !!disabled,
    });

    return <TextInput classNames={classNames} {...props} ref={ref} />;
  },
);

type ClassNames = Partial<Record<__InputStylesNames, string>>;

function getCorrectClassNames({
  hasErrorProp,
  hasDisabledProp,
}: {
  hasErrorProp: boolean;
  hasDisabledProp: boolean;
}) {
  const errorClasses: ClassNames = {
    input: "placeholder-red-500 border-red-500 text-red-500 rounded",
    error: "text-red-500",
  };

  const commonClasses: ClassNames = {
    input: "rounded border-solid border py-4 px-5 w-full dark:bg-primaryBlack",
    label: "text-darkBlue80 dark:text-darkBlue20",
    wrapper: "mt-1.5",
  };

  const defaultClasses: ClassNames = {
    input: clsx(
      "text-black border-mediumGrey placeholder-mediumGrey",
      "dark:text-darkBlue40 dark:border-darkBlue80 dark:placeholder-darkBlue40",
    ),
  };

  const disabledClasses: ClassNames = {
    input: "placeholder-black border-mediumGrey",
  };

  if (hasDisabledProp) {
    return combineClassNames({
      commonClasses,
      specificClasses: disabledClasses,
    });
  }

  if (hasErrorProp) {
    return combineClassNames({
      commonClasses,
      specificClasses: errorClasses,
    });
  }

  return combineClassNames({
    commonClasses,
    specificClasses: defaultClasses,
  });
}

function combineClassNames({
  commonClasses,
  specificClasses,
}: {
  commonClasses: ClassNames;
  specificClasses: ClassNames;
}): ClassNames {
  // get all keys in both objects
  const keys = new Set([
    ...Object.keys(commonClasses),
    ...Object.keys(specificClasses),
  ]) as Set<__InputStylesNames>;

  // Combine the values of both objects
  return Array.from(keys).reduce<ClassNames>((obj, key) => {
    obj[key] = (commonClasses[key] || "") + " " + (specificClasses[key] || "");
    return obj;
  }, {});
}
