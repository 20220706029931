"use client";
import { ArrowLink } from "@/components/ArrowLink/ArrowLink";
import { TypeNavigationElementSkeleton } from "@/lib/contentful/contentfulTypes";
import { List } from "@mantine/core";
import { Entry } from "contentful";

export function NavigationListClient({
  items,
  title,
}: {
  title: string;
  items: Entry<
    TypeNavigationElementSkeleton,
    "WITHOUT_LINK_RESOLUTION",
    string
  >[];
}) {
  return (
    <section>
      <h3 className="w-full border-b border-b-darkBlue40 px-5 pb-5 text-darkBlue40">
        {title}
      </h3>
      <List className="mobileH2 md:heading2 m-auto w-full font-light">
        {items.map((item) => (
          <List.Item key={item.fields.description}>
            <ArrowLink
              href={item.fields.url}
              label={item.fields.label}
              ariaLabel={item.fields.description}
            />
          </List.Item>
        ))}
      </List>
    </section>
  );
}
