"use client";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import clsx from "clsx";

export default function LeafletMap({
  latlng,
  className,
}: {
  latlng: { lat: number; lng: number };
  className?: string;
}) {
  return (
    <MapContainer
      className={clsx(className, "z-0", "h-96", "w-full", "md:h-full")}
      center={latlng}
      zoom={18}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={latlng} />
    </MapContainer>
  );
}
