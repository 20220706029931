import { TypeContactSkeleton } from "@/lib/contentful/contentfulTypes";
import clsx from "clsx";
import { Entry } from "contentful";
import Image from "next/image";
import parsePhoneNumber from "libphonenumber-js";

export function ContactCard({
  model,
}: {
  model: Entry<TypeContactSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
}) {
  const imageAsset = model.fields.image;

  return (
    <figure
      className={clsx(
        "bg-white",
        "dark:bg-primaryBlack",
        "px-10",
        "py-5",
        "flex",
        "flex-col",
        "md:items-start",
        "items-center",
        "rounded",
        "animate-fade",
      )}
      aria-label="Contact Information Card"
    >
      <Image
        className={clsx(
          "overflow-hidden",
          "rounded-full",
          "md:h-[120px]",
          "md:w-[120px] ",
          "h-[80px]",
          "w-[80px]",
          "row-span-2",
          "mb-5",
        )}
        src={`https:${imageAsset?.fields.file?.url}`}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        alt={imageAsset?.fields.description || ""}
        width={imageAsset?.fields.file?.details.image?.width}
        height={imageAsset?.fields.file?.details.image?.height}
      />
      <h2 className={clsx("font-miller", "text-2xl")}>{model.fields.name}</h2>
      <p className={clsx("col-span-2")}>{model.fields.title}</p>
      <a
        href={`tel:${parsePhoneNumber(
          model.fields.phone,
          "SE",
        )?.formatInternational()}`}
        className={clsx("hoverOrFocus:underline", "col-span-2")}
        aria-label="Phone Number"
      >
        {parsePhoneNumber(model.fields.phone, "SE")?.formatInternational()}
      </a>
      <a
        href={`mailto:${model.fields.email}`}
        className={clsx("hoverOrFocus:underline", "text-primaryOrange")}
        aria-label="Email Address"
      >
        {model.fields.email}
      </a>
    </figure>
  );
}
